import React from "react";
import classes from "./Service.module.css";

import Hero from "../components/layout/Hero";
import Contact from "../components/layout/Contact";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";

export default class ServicePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: 0,
      loadingTotal: 1,
      isLoading: true,
      loadedItem: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("nav-1").classList.remove("show");
    if (
      !document.getElementById("nav-toggler-1").classList.contains("collapsed")
    ) {
      document.getElementById("nav-toggler-1").click();
    }

    document.body.style.overflow = "initial";

    this.Load();
  }

  Load() {
    let query =
      '{serviceCollection(where:{slug:"' +
      this.props.match.params.slug +
      '"}){items{title,subheading,priceIsk,includedText,termsText}}}';

    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/7mck7fbwfpae/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer CORMin56zbx3bXnlz2lzIvVMZvb_H7lJ7k56pqsXeAo",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        this.setState({
          loading: this.state.loading + 1,
          loadedItem: data.serviceCollection.items[0],
        });

        const script = document.createElement("script");
        script.src =
          "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=b8f8ea4f-4784-493c-bad6-a8541da2e38f";
        script.async = true;
        document.body.appendChild(script);
      });
  }

  componentDidUpdate() {
    if (
      this.state.isLoading &&
      this.state.loading === this.state.loadingTotal
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Hero text="" imageUrl="/img/truss.jpg" />
        {this.state.isLoading ? (
          <section className={classes.load}>
            <Spinner animation="border" role="status"></Spinner>
            &nbsp;<span>Loading service...</span>
          </section>
        ) : null}
        <Container className={classes.service}>
          <Stack gap={2}>
            <h3></h3>
            <h1 className="mt-5">
              {this.state.loadedItem.title} on the{" "}
              {this.state.loadedItem.subheading}
            </h1>
            <p>by Fjallaþjónustan ehf.</p>
            <h4 className={classes.price}>
              ISK {this.state.loadedItem.priceIsk}
            </h4>
            <br />
            <h5>
              <strong>Included</strong>
            </h5>
            <p>{this.state.loadedItem.includedText}</p>
            <br />
            <h5>
              <strong>Terms</strong>
            </h5>
            <p>{this.state.loadedItem.termsText}</p>
            <script
              type="text/javascript"
              src="https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=b8f8ea4f-4784-493c-bad6-a8541da2e38f"
              async
            ></script>

            <div
              class="bokunWidget"
              data-src="https://widgets.bokun.io/online-sales/b8f8ea4f-4784-493c-bad6-a8541da2e38f/experience-calendar/655065"
            ></div>
            <noscript>
              Please enable javascript in your browser to book
            </noscript>

            <hr className="mb-0 mt-5" />
            <Contact />
          </Stack>
        </Container>
      </div>
    );
  }
}
