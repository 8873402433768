import React from "react";
import classes from "./UnderConstruction.module.css";

import Hero from "../components/layout/Hero";
import Contact from "../components/layout/Contact";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";

export default class UnderConstructionPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("nav-1").classList.remove("show");
    if (
      !document.getElementById("nav-toggler-1").classList.contains("collapsed")
    ) {
      document.getElementById("nav-toggler-1").click();
    }
  }

  render() {
    return (
      <div>
        <Hero text="UNDER CONSTRUCTION" imageUrl="/img/thorhandstand.jpg" />
        <Container className={classes.underconstruction}>
          <Stack gap={0} className="mt-5">
            <h1>THIS PAGE IS UNDER CONSTRUCTION</h1>
            <p>Hope to see you back when it is ready!</p>
            <hr className="mt-5 mb-0" />
            <Contact />
          </Stack>
        </Container>
      </div>
    );
  }
}
