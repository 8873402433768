import React from "react";
import classes from "./Adventure.module.css";

import Contact from "../components/layout/Contact";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";

export default class AdventurePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: 0,
      loadingTotal: 2,
      isLoading: true,
      bokunId: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("nav-1").classList.remove("show");
    if (
      !document.getElementById("nav-toggler-1").classList.contains("collapsed")
    ) {
      document.getElementById("nav-toggler-1").click();
    }

    document.body.style.overflow = "initial";

    if (window.localStorage.getItem("bokunId")) {
      window.localStorage.removeItem("bokunId");
      window.location.reload();
    } else {
      this.Load();
    }
  }

  Load() {
    let query =
      '{adventureCollection(where:{slug:"' +
      this.props.match.params.slug +
      '"}){items{bokunId}}}';

    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/7mck7fbwfpae/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer CORMin56zbx3bXnlz2lzIvVMZvb_H7lJ7k56pqsXeAo",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        this.setState({
          loading: this.state.loading + 1,
          bokunId: data.adventureCollection.items[0].bokunId,
        });

        window.localStorage.setItem(
          "bokunId",
          data.adventureCollection.items[0].bokunId
        );

        const script = document.createElement("script");
        script.src =
          "https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=b8f8ea4f-4784-493c-bad6-a8541da2e38f";
        script.async = true;
        document.body.appendChild(script);
      });

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].target.clientHeight > 1500) {
        setTimeout(() => {
          this.setState({
            loading: this.state.loading + 1,
          });
          document.body.style.overflow = "initial";
        }, 500);
      }
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    setTimeout(() => {
      if (this.state.isLoading)
        this.setState({
          loading: this.state.loading + 1,
        });
      document.body.style.overflow = "initial";
    }, 5000);
  }

  componentDidUpdate() {
    if (
      this.state.isLoading &&
      this.state.loading === this.state.loadingTotal
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <section className={classes.load}>
            <Spinner animation="border" role="status"></Spinner>
            &nbsp;<span>Loading adventure...</span>
          </section>
        ) : null}
        <div
          className="bokunWidget"
          data-src={
            "https://widgets.bokun.io/online-sales/b8f8ea4f-4784-493c-bad6-a8541da2e38f/experience/" +
            this.state.bokunId
          }
        ></div>
        <noscript>Please enable javascript in your browser to book</noscript>
        <Container className={classes.adventure}>
          <Stack gap={2}>
            <hr className="mb-0 mt-5" />
            <Contact />
          </Stack>
        </Container>
      </div>
    );
  }
}
