import classes from "./TourList.module.css";
import React from "react";
import TourItem from "./TourItem";

import ToggleBadge from "../ui/ToggleBadge";
import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";

export default class TourList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedTours: {},
      displayTours: {},
      loading: 0,
      loadingTotal: 2,
      isLoading: true,
      loadedTypes: {},
    };

    this.filter = this.filter.bind(this);
  }

  filter(a, b) {
    console.log("filtering " + a + " to " + b);
    let e = this.state.loadedTypes.find((e) => e.name === a);
    e.filter = !e.filter;

    // rerender the entire component with new data
    this.state.loadedTours.every((e) => (e.filter = true));
    this.state.loadedTypes
      .filter((e) => e.filter === false)
      .map((lt) => {
        this.state.loadedTours
          .filter((e) => e.filter === true)
          .map((tour) => {
            if (
              tour.typeCollection.items.filter((e) => e.name === lt.name)
                .length > 0
            ) {
              tour.filter = false;
            }
          });
      });

    let result = this.state.loadedTours.filter((e) => e.filter === true);

    this.setState({
      displayTours: result,
    });

    if (window.innerWidth >= 1400) {
      if (result.length < 4)
        document
          .getElementsByClassName("tl")[0]
          .classList.add("justify-content-center");
      else
        document
          .getElementsByClassName("tl")[0]
          .classList.remove("justify-content-center");
    } else if (window.innerWidth >= 1200) {
      if (result.length < 3)
        document
          .getElementsByClassName("tl")[0]
          .classList.add("justify-content-center");
      else
        document
          .getElementsByClassName("tl")[0]
          .classList.remove("justify-content-center");
    } else if (window.innerWidth >= 768) {
      if (result.length < 4)
        document
          .getElementsByClassName("tl")[0]
          .classList.add("justify-content-center");
      else
        document
          .getElementsByClassName("tl")[0]
          .classList.remove("justify-content-center");
    }
  }

  componentDidMount() {
    this.LoadFilters();
    this.LoadTours();
  }

  componentDidUpdate() {
    if (
      this.state.isLoading &&
      this.state.loading === this.state.loadingTotal
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  LoadFilters() {
    let query = `
    {
      typeCollection {
        items {
          name
        }
      }
    }
`;

    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/7mck7fbwfpae/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer CORMin56zbx3bXnlz2lzIvVMZvb_H7lJ7k56pqsXeAo",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data
        data.typeCollection.items.every((e) => (e.filter = true));

        this.setState({
          loading: this.state.loading + 1,
          loadedTypes: data.typeCollection.items,
        });
      });
  }

  LoadTours() {
    let query = `
    {
      adventureCollection {
        items {
          sys { id },
          slug,
          title,
          image {
            description
            url
          },
          typeCollection {
            items {
              ... on Type {
                name
              }
            }
          }
        }
      }
    }    
`;

    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/7mck7fbwfpae/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer CORMin56zbx3bXnlz2lzIvVMZvb_H7lJ7k56pqsXeAo",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        // rerender the entire component with new data+

        data.adventureCollection.items.every((e) => (e.filter = true));

        this.setState({
          loading: this.state.loading + 1,
          loadedTours: data.adventureCollection.items,
          displayTours: data.adventureCollection.items,
        });
      });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <section>
          <Spinner animation="border" role="status"></Spinner>
          &nbsp;<span>Loading...</span>
        </section>
      );
    }
    return (
      <Stack gap={0}>
        <Stack
          direction="horizontal"
          gap={2}
          className={classes.badges + " mb-3"}
        >
          {this.state.loadedTypes.map((type) => (
            <ToggleBadge filter={this.filter} name={type.name}></ToggleBadge>
          ))}
        </Stack>
        {this.state.displayTours.length === 0 && (
          <h2>No adventures to display with current filters.</h2>
        )}
        <Stack direction="horizontal" gap={3} className={classes.list + " tl"}>
          {this.state.displayTours.map((tour) => (
            <TourItem
              id={tour.id}
              slug={tour.slug}
              imageUrl={tour.image.url}
              imageAlt={tour.image.description}
              title={tour.title}
              types={tour.typeCollection.items}
            />
          ))}
        </Stack>
      </Stack>
    );
  }
}
