import React from "react";
import classes from "./Terms.module.css";

import Hero from "../components/layout/Hero";
import Contact from "../components/layout/Contact";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";

export default class TermsConditionsPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("nav-1").classList.remove("show");
    if (
      !document.getElementById("nav-toggler-1").classList.contains("collapsed")
    ) {
      document.getElementById("nav-toggler-1").click();
    }
  }

  render() {
    return (
      <div>
        <Hero
          text="TERMS & CONDITIONS"
          imageUrl="/img/strokkur-geysir-iceland-north-europe-travel.jpg"
        />
        <Container className={classes.terms}>
          <Stack gap={0} className="mt-5">
            <h1>Terms &amp; Conditions</h1>
            <p>
              Before making a reservation at <em>Fjallaþjónustan</em> we
              encourage you to read these Terms and Conditions carefully, as
              they constitute a contract agreement between{" "}
              <em>Fjallaþjónustan</em> and you from the time the booking is
              made.
            </p>
            <p>
              The person who makes the booking accepts these conditions on
              behalf of all the people in the group and is responsible for all
              payments due. By submitting a booking you confirm to us that you
              comply with these arrangements.
            </p>
            <p>
              <strong>All cancellations must be made by e-mail.</strong>
            </p>
            <h2>Cancellation of one-day Tours</h2>
            <p>All cancellations must be made by e-mail at fjalla@fjalla.is</p>
            <p>
              For all bookings of day tours operated by{" "}
              <em>Fjallaþjónustan ehf</em>. a minimum of 48 hours’ notice of
              cancellation is required for a 90% refund. If cancellation is made
              after these time limits pass no refund is given.
            </p>
            <p>
              10% of the price of your trip is non-refundable, this percentage
              reflects our cost of booking and preparing your trip.
            </p>
            <h2>
              Cancellation of Multi-day Tours, Tailor made Tours and Private
              Tours
            </h2>
            <p>All cancellations must be made by e-mail at fjalla@fjalla.is</p>
            <p>
              As we start to incur costs from the time the contract is confirmed
              we will apply cancellation charges as shown below from the time
              when <strong>written</strong> <strong>notification</strong> of the
              cancellation is received:
            </p>
            <p>
              Cancellations with more than 50 days’ notice before trip
              departure:
            </p>
            <ul>
              <li>
                You will be refunded 90% of the total tour price. 10% is
                non-refundable, as this represents our direct cost of booking
                and preparing your tour.
              </li>
            </ul>
            <p>
              Cancellations with 20 to 49 days’ notice before trip departure:
            </p>
            <ul>
              <li>
                You will be refunded 50% of your total tour price. 50% is
                non-refundable, as this represents our direct cost of cancelling
                any arrangements made for your tour, such as sleeping
                arrangements at hotels and mountain huts.
              </li>
            </ul>
            <p>Cancellations with 19 days’ notice before trip departure:</p>
            <ul>
              <li>
                No refund will be given if tours are cancelled with less than 19
                days’ notice, as <em>Fjallaþjónustan</em> will be charged for
                all costs such as guides, transport, accommodation etc.
              </li>
            </ul>
            <h2>
              <strong>
                Tailor made Tours
                <br />
              </strong>
            </h2>
            <ul>
              <li>
                Confirmation of 50% must be paid at booking and full payment
                must have arrived 6 weeks prior to departure.
              </li>
            </ul>
            <h2>Changes and cancellations by Fjallaþjónustan</h2>
            <p>
              All information on the web regarding the services provided
              by&nbsp;<em>Fjallaþjónustan</em> is correct at the time of its
              publishing.
            </p>
            <p>
              All trips are weather and condition dependent and we reserve the
              right to change your itinerary or cancel your trip last minute
              with your safety in mind.
            </p>
            <p>
              You will be refunded all of your booking fee if{" "}
              <em>Fjallaþjónustan</em> cancels your trip when no safety or
              weather concerns require cancellations. You will be refunded 90%
              of your booking fee if <em>Fjallaþjónustan</em> cancels the trip
              last minute because of weather or safety concerns.
            </p>
            <p>
              Northern Light trips carry the inherent risk that no northern
              lights may be found. In that case, <em>Fjallaþjónustan</em> will
              offer its customers a rebooking of their tour on a different date,
              but will not offer refunds.
            </p>
            <h2>Valitor</h2>
            <p>
              Valitor Retail is the reseller of services provided by This is
              Iceland ehf.&nbsp; Valitor will appear on your card statement.
            </p>
            <h2>Responsibility of participants</h2>
            <p>
              All trips are undertaken on the responsibility of its
              participants. <em>Fjallaþjónustan</em> does not assume any
              responsibility for accidents that are caused by its customers, or
              can be traced to their own actions, or failure to use equipment or
              clothing as recommended by their guide, or failure to respond to
              safety instruction from their guide.
            </p>
            <p>
              By booking a trip with <em>Fjallaþjónustan</em>, you accept the
              responsibility that all personal, financial and contact details
              supplied are correct. <u>Failure to execute</u> the trip or
              communicate with our customers when inadequate or wrong
              information is provided, may result in the cancellation of the
              trip, with no refund available.
            </p>
            <p>
              <strong>
                Individual travel insurance is not included in the tour price
                and all medical costs and other costs involved must be paid by
                the client.
              </strong>{" "}
              It is therefore strongly recommended that participants take out
              personal travel and/or medical insurance prior to departure.
            </p>
            <h2>Liability</h2>
            <p>
              <em>Fjallaþjónustan</em> acts in good faith and cannot be held
              responsible for any schedule changes caused by weather or road
              conditions, any loss, damage, accidents, sickness or{" "}
              <u>acts of god</u> such as natural catastrophes, strikes or any
              other irregularities beyond the control of{" "}
              <em>Fjallaþjónustan</em>. Luggage is at the owner’s risk
              throughout the tour.
            </p>
            <hr className="mt-5 mb-0" />
            <Contact />
          </Stack>
        </Container>
      </div>
    );
  }
}
