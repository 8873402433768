import { Link } from "react-router-dom";

import classes from "./ServiceItem.module.css";

export default function ServiceItem(props) {
  return (
    <Link to={"/services/" + props.slug} className={classes.link}>
      <li key={props.id} className={classes.item}>
        <img alt={props.imageAlt} src={props.imageUrl} />
        <h1>
          {props.title}
          <span className="mt-1">Service</span>
        </h1>
      </li>
    </Link>
  );
}
