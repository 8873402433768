import { Route, Switch } from "react-router-dom";

import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import ContactPage from "./pages/Contact";
import AdventurePage from "./pages/Adventure";
import ServicePage from "./pages/Service";
import TermsPage from "./pages/Terms";
import UnderConstructionPage from "./pages/UnderConstruction";

import Layout from "./components/layout/Layout";

function App() {
  return (
    <Layout>
      <Switch>
        <Route path="/terms" component={TermsPage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/services/:slug" component={ServicePage} />
        <Route path="/services" component={UnderConstructionPage} />
        <Route path="/adventures/:slug" component={UnderConstructionPage} />
        <Route path="/adventures" component={UnderConstructionPage} />
        <Route path="/" component={UnderConstructionPage} />
      </Switch>
    </Layout>
  );
}

export default App;
