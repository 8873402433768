import React from "react";
import classes from "./ToggleBadge.module.css";

import Badge from "react-bootstrap/Badge";

export default class ToggleBadge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: true,
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.filter(this.props.name, !this.state.filter);
    this.setState({
      filter: !this.state.filter,
    });
  }

  render() {
    return (
      <Badge
        onClick={this.handleClick}
        className={classes.badge}
        pill
        bg="dark"
      >
        <i>
          {
            new DOMParser().parseFromString(
              this.state.filter ? "&#10003;" : "&#10005;",
              "text/html"
            ).body.textContent
          }
        </i>{" "}
        {this.props.name}
      </Badge>
    );
  }
}
