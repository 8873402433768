import React from "react";
import classes from "./Contact.module.css";

import { Link } from "react-router-dom";

import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";

export default class Contact extends React.Component {
  render() {
    return (
      <Stack className={classes.contact + " mt-5"} gap={2}>
        <Stack direction="horizontal" gap={5} className="mb-5">
          <Stack className={classes.text} gap={2}>
            <strong>Contact us</strong>
            Send us a message, or give us a call.
            <br />
            Let's plan the perfect trip for you.
          </Stack>
          <Stack className={classes.links} gap={2}>
            <a href="tel:+3548888889">
              <i class="fas fa-phone"></i> (354) 888 888 9
            </a>
            <a href="mailto:fjalla@fjalla.is">
              <i class="fas fa-envelope"></i> fjalla@fjalla.is
            </a>
            <a href="http://m.me/fjallathjonustan">
              <i class="fab fa-facebook-messenger"></i> m.me/fjallathjonustan
            </a>
          </Stack>
        </Stack>
        <Link to="/">
          <img alt="Fjalla logo" src="/img/FJALLATRAVELLOGOWHITE.svg"></img>
        </Link>
        <Stack
          className="justify-content-center"
          direction="horizontal"
          gap={2}
        >
          <Link to="/about">
            <Button variant="dark">About us</Button>
          </Link>
          <Link to="/contact">
            <Button variant="dark">Contact us</Button>
          </Link>
          <Link to="/terms">
            <Button variant="dark">Terms & Conditions</Button>
          </Link>
        </Stack>
        <Stack
          className={classes.social + " mb-5 justify-content-center"}
          direction="horizontal"
          gap={2}
        >
          <a href="http://facebook.com/fjallathjonustan">
            <i className={classes.facebook + " fab fa-facebook-square"}></i>
          </a>
          <a href="http://instagram.com/fjallaviking">
            <i className={classes.instagram + " fab fa-instagram-square"}></i>
          </a>
        </Stack>
      </Stack>
    );
  }
}
