import classes from "./Header.module.css";

import { Link } from "react-router-dom";

import Navbar from "react-bootstrap/NavBar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";

function Header() {
  return (
    <Navbar expand="*" className={classes.header + " navbar-dark"}>
      <Container>
        <Navbar.Brand href="/" className={classes.brand}>
          <img
            alt="Fjalla logo"
            src="/img/FJALLATRAVELLOGOTRANSPARENT.svg"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle
          className={classes.toggler}
          aria-controls="nav-1"
          id="nav-toggler-1"
        />
        <Navbar.Collapse className={classes.collapse} id="nav-1">
          <Nav className={classes.nav + " me-auto"}>
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/adventures">
              Adventures
            </Nav.Link>
            <Nav.Link as={Link} to="/services">
              Services
            </Nav.Link>
            <Nav.Link as={Link} to="/about">
              About
            </Nav.Link>
            <Nav.Link as={Link} to="/contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
