import React from "react";

import Hero from "../components/layout/Hero";
import TourList from "../components/tours/TourList";
import ServiceList from "../components/services/ServiceList";
import Contact from "../components/layout/Contact";

import classes from "./Home.module.css";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";

export default class HomePage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("nav-1").classList.remove("show");
    if (
      !document.getElementById("nav-toggler-1").classList.contains("collapsed")
    ) {
      document.getElementById("nav-toggler-1").click();
    }
  }

  render() {
    return (
      <div>
        <Hero text="HIKE ICELAND WITH US" imageUrl="/img/hero-image.jpg" />
        <Container className={classes.home}>
          <Stack gap={2} className="mt-5">
            <TourList />
            <ServiceList />
            <hr className="mt-5 mb-0" />
            <Contact />
          </Stack>
        </Container>
      </div>
    );
  }
}
