import { Link } from "react-router-dom";

import classes from "./TourItem.module.css";

function TourItem(props) {
  return (
    <Link to={"/adventures/" + props.slug} className={classes.link}>
      <li key={props.id} className={classes.item}>
        <img alt={props.imageAlt} src={props.imageUrl} />
        <h1>
          {props.title}
          <span className="mt-1">
            {props.types.map((e) => e.name).join(", ")}
          </span>
        </h1>
      </li>
    </Link>
  );
}

export default TourItem;
