import React from "react";
import classes from "./About.module.css";

import Hero from "../components/layout/Hero";
import Contact from "../components/layout/Contact";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";

export default class AboutPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("nav-1").classList.remove("show");
    if (
      !document.getElementById("nav-toggler-1").classList.contains("collapsed")
    ) {
      document.getElementById("nav-toggler-1").click();
    }
  }

  render() {
    return (
      <div>
        <Hero
          text="GET TO KNOW US"
          imageUrl="/img/LANGJOKULL_ICE_CAVE_ADVENTURE.jpg"
        />
        <Container className={classes.about}>
          <Stack gap={2} className="mt-5">
            <Stack direction="horizontal" gap={3} className={classes.staff}>
              <Card className={classes.card} style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  alt="Maggnus Viking in a swimming pool"
                  src="img/maggnus-viking.jpg"
                />
                <Card.Body>
                  <Card.Title>Maggnus Viking</Card.Title>
                  <Card.Subtitle>Owner, Operations</Card.Subtitle>
                </Card.Body>
              </Card>
              <Card className={classes.card} style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  alt="Unnur Jonsdottir in a blue dress"
                  src="img/unnur-jonsdottir.jpg"
                />
                <Card.Body>
                  <Card.Title>Unnur Jonsdottir</Card.Title>
                  <Card.Subtitle>Owner, Marketing</Card.Subtitle>
                </Card.Body>
              </Card>
            </Stack>
            <h2 className="mt-4">
              We aim to create the most memorable experiences
            </h2>
            <h3 className="h5">
              Our incredbly talented and enthusiastic staff will make sure of
              that.
            </h3>
            <ul>
              <li>
                Our guides are very knowledgeable about the environments in
                which we operate.
              </li>
              <li>
                We try our best to respect nature and always strive for
                sustainability, although there is still a long way to go.
              </li>
              <li>
                We are not afraid to get personal, share real stories and make
                you feel like part of the family.
              </li>
            </ul>
            <Alert variant="success" className={classes.alert}>
              <h2 className="mt-4">Green promise</h2>
              <p>
                To us sustainability is a natural part of being a successful
                business and we always strive to act in an ethical, transparent
                and responsible way.
              </p>
            </Alert>
            <Stack
              direction="horizontal"
              gap={3}
              className={classes.gallery + " justify-content-center"}
            >
              <img
                width="200"
                variant="top"
                alt="Travel Agency Certificate"
                src="img/leyfi_2018_ferdaskrifst.png"
              />
              <img
                width="200"
                variant="top"
                alt="COVID-19 Comply Commitment"
                src="img/clean-safe-pdf-300x300.jpg"
              />
            </Stack>
            <hr className="mb-5 mt-5" />
            <Stack
              direction="horizontal"
              gap={3}
              className={classes.gallery + " justify-content-center"}
            >
              <Card className={classes.card} style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  alt="Kirkjufell mountain"
                  src="img/SNAEFELLSNES_PENINSULA.jpg"
                />
              </Card>
              <Card className={classes.card} style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  alt="Northern lights in Iceland"
                  src="img/NORTHERN_LIGHTS_HUNT.jpg"
                />
              </Card>
              <Card className={classes.card} style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  alt="Strokkur geysir erupting boiling water"
                  src="img/strokkur-geysir-iceland-north-europe-travel.jpg"
                />
              </Card>
              <Card className={classes.card} style={{ width: "18rem" }}>
                <Card.Img
                  variant="top"
                  alt="Maggnus Viking doing a handstand on the edge of Valahnukur mountain"
                  src="img/thorhandstand.jpg"
                />
              </Card>
            </Stack>
            <hr className="mt-5 mb-0" />
            <Contact />
          </Stack>
        </Container>
      </div>
    );
  }
}
