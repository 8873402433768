import React from "react";
import classes from "./Contact.module.css";

import Hero from "../components/layout/Hero";
import Contact from "../components/layout/Contact";

import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

export default class ContactPage extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    document.getElementById("nav-1").classList.remove("show");
    if (
      !document.getElementById("nav-toggler-1").classList.contains("collapsed")
    ) {
      document.getElementById("nav-toggler-1").click();
    }
  }

  render() {
    return (
      <div>
        <Hero text="CONTACT US" imageUrl="/img/NORTHERN_LIGHTS_HUNT.jpg" />
        <Container>
          <Stack gap={2} className="mt-5">
            <Alert variant="success" className={classes.alert}>
              <h2 className="mt-4">Coronavirus FAQs</h2>
              <h3 className="h5">Is my trip going ahead?</h3>
              <p>
                All the trips available on our website are going ahead. In the
                unfortunately event that your trip is cancelled, we will reach
                out to you directly to confirm your options.
              </p>
              <h3 className="h5">What happens if my trip is cancelled?</h3>
              <p>
                In the unlikely event that your trip is cancelled by us, we will
                reach out directly to offer you the following options:
              </p>
              <ul>
                <li>
                  <strong>A free date change</strong> - you can choose a new
                  date for the same trip.
                </li>
                <li>
                  <strong>A full refund</strong> - we understand sometimes these
                  options won't work for you. In this case we'll offer you a
                  full refund of any payments made for the trip, and we'll also
                  return any credits or single-use discounts so that you can use
                  them again.
                </li>
              </ul>
              <h3 className="h5">
                What happens if Government restrictions prevent me from
                travelling?
              </h3>
              <p>
                You can also opt for a free date change, if a change in
                Government restrictions prevent you from travelling. To do this
                you simply need to let us know either 30 days before the trip,
                or if the restrictions are announced after this point you can
                let us know any time up to 24 hours before the trip starts.
              </p>
              <h3 className="h5">What else can I do to protect myself?</h3>
              <p>
                We highly recommend taking out comprehensive travel insurance.
                Whilst we can't provide formal advice on travel insurance, we do
                understand many providers are now including Covid-19 cover.
              </p>
            </Alert>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Email address*</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  required
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Name*</Form.Label>
                <Form.Control type="text" placeholder="John Doe" required />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Subject*</Form.Label>
                <Form.Control type="text" required />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label>Message*</Form.Label>
                <Form.Control as="textarea" rows={3} required />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
            <hr className="mt-5 mb-0" />
            <Contact />
          </Stack>
        </Container>
      </div>
    );
  }
}
