import React from "react";
import classes from "./Hero.module.css";

export default class Hero extends React.Component {
  render() {
    return (
      <div
        className={
          classes.hero +
          (this.props.small ? " " + classes.small : "") +
          (this.props.placeholder ? " " + classes.placeholder : "")
        }
      >
        <img
          className="d-block w-100"
          src={this.props.imageUrl}
          alt="Stakkholtsgja Canyon"
        />
        <h1 className="m-3 mt-5 pt-5 pt-md-0">{this.props.text}</h1>
        <svg height="50" width="1920">
          <polygon points="1000,50 0,0 0,50" style={{ fill: "#fff" }} />
        </svg>
      </div>
    );
  }
}
