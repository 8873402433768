import classes from "./ServiceList.module.css";
import React from "react";

import ServiceItem from "./ServiceItem";

import Stack from "react-bootstrap/Stack";
import Spinner from "react-bootstrap/Spinner";

export default class Service extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadedServices: {},
      loading: 0,
      loadingTotal: 1,
      isLoading: true,
    };
  }

  componentDidMount() {
    this.LoadServices();
  }

  componentDidUpdate() {
    if (
      this.state.isLoading &&
      this.state.loading === this.state.loadingTotal
    ) {
      this.setState({
        isLoading: false,
      });
    }
  }

  LoadServices() {
    let query = `
    {
      serviceCollection {
        items {
          sys { id },
          slug,
          title,
          image {
            description
            url
          }
        }
      }
    }    
`;

    window
      .fetch(`https://graphql.contentful.com/content/v1/spaces/7mck7fbwfpae/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Authenticate the request
          Authorization: "Bearer CORMin56zbx3bXnlz2lzIvVMZvb_H7lJ7k56pqsXeAo",
        },
        // send the GraphQL query
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
        }

        this.setState({
          loading: this.state.loading + 1,
          loadedServices: data.serviceCollection.items,
        });
      });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <section>
          <Spinner animation="border" role="status"></Spinner>
          &nbsp;<span>Loading...</span>
        </section>
      );
    }
    return (
      <Stack gap={0}>
        <Stack direction="horizontal" gap={3} className={classes.list + " tl"}>
          {this.state.loadedServices.map((service) => (
            <ServiceItem
              id={service.id}
              slug={service.slug}
              imageUrl={service.image.url}
              imageAlt={service.image.description}
              title={service.title}
            />
          ))}
        </Stack>
      </Stack>
    );
  }
}
